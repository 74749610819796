import React, { useState, useEffect, useRef, useMemo } from "react";
import Styles from "../../MetricData/metricdata.module.css";
import { useParams, Link } from "react-router-dom";
import API from "../../../../Services/API";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useDisclosure,
} from "@chakra-ui/react";
import LoaderSpinner from "../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner";

const ExecutiveLeaderReporting = () => {
  const token = window.localStorage.getItem("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [elrSectionOne, setElrSectionOne] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [newErrorMessage, setNewErrorMessage] = useState(null);

  // Fetch Data
  useEffect(() => {
    if (!token) return;

    setIsLoading(true);
    API.get(`/elr-dashboard`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setElrSectionOne(response.data || []);
        setIsLoading(false);
      })
      .catch((error) => {
        setNewErrorMessage(error.response?.data?.error || "An error occurred");
        setIsLoading(false);
      });
  }, [token]);

  // Filter data using useMemo to improve performance
  const filteredData = useMemo(() => {
    return elrSectionOne?.filter((item) =>
      [
        "Approve",
        "Rejected",
        "Approved by Comms Team",
        "Approved by Action Office",
        "Approved by Super Admin",
      ].includes(item.approval_status)
    );
  }, [elrSectionOne]);

  return (
    <>
      {isLoading && <LoaderSpinner />}
      {errorMessage && <Box p="5px">{errorMessage}</Box>}
      {newErrorMessage && <Box fontSize="13px">{newErrorMessage}</Box>}

      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr className={Styles.tr}>
              <Th className={Styles.th}>Period - Year</Th>
              <Th className={Styles.th}>Period - Qtr</Th>
              <Th className={Styles.th}>Executive Level Review</Th>
              <Th className={Styles.th}>Focus Area</Th>
              <Th className={Styles.th}>Created On</Th>
              <Th className={Styles.th}>Updated On</Th>
              <Th className={Styles.th}>Created By</Th>
              <Th className={Styles.th}>Approval Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!filteredData?.length ? (
              <Tr>
                <Td colSpan="8">There is no data available.</Td>
              </Tr>
            ) : (
              filteredData.map((item) => (
                <Tr key={item.id || Math.random()} className={Styles.tr}>
                  <Td className={Styles.td}>
                    <Box maxW="150px">{item.fiscal_year_name || "N/A"}</Box>
                  </Td>
                  <Td className={Styles.td}>
                    <Box maxW="150px">{item.fiscal_quarter_name || "N/A"}</Box>
                  </Td>
                  <Td className={Styles.td}>
                    <Box maxW="150px">{item.label || "N/A"}</Box>
                  </Td>
                  <Td className={Styles.td}>
                    <Box maxW="150px">
                      <Link
                        to={`/action/${item.module_id}/${item.action_name}#executiveLeaderReporting#executiveleadershipreviews`}
                      >
                        {item.action_name || "N/A"}
                      </Link>
                    </Box>
                  </Td>
                  <Td className={Styles.td}>
                    {item.created_at
                      ? new Date(item.created_at).toLocaleDateString()
                      : "N/A"}
                  </Td>
                  <Td className={Styles.td}>
                    {item.updated_at
                      ? new Date(item.updated_at).toLocaleDateString()
                      : "N/A"}
                  </Td>
                  <Td className={Styles.td}>
                    <Box maxW="150px">{item.user || "N/A"}</Box>
                  </Td>
                  <Td className={Styles.td}>
                    <Box maxW="150px">{item.approval_status || "N/A"}</Box>
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ExecutiveLeaderReporting;
