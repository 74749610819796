import React,{useEffect, useState} from 'react';
import API from '../../../../Services/API';
import {
  InputRightElement,
  InputGroup,
  Input,
  Box,
  Card,
  CardBody,
  TableContainer,
  Table,
  Thead,
  Tr,
  Tbody,
  Th,
  Td,
  Avatar,
  IconButton,
  Flex,
  Text,
} from '@chakra-ui/react';
import { Checkbox} from '@chakra-ui/react'
import { SearchIcon, CloseIcon } from '@chakra-ui/icons';
import AddUser from './AddUser/AddUser';
import DeleteUser from './DeleteUser/DeleteUser';
import EditUser from './EditUser/EditUser';

const UsersAndPermission = () => {
  const token = window.localStorage.getItem('accessToken');
  const [users, setUsers] = useState([])
  const [searchKey, setSearchKey] = useState(null)

  useEffect(()=>{
    API.get('/team-member ', { 
      headers: { Authorization: 'Bearer ' + token } 
    }).then(response=>{
      setUsers((response.data).sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      ))
    }).catch(error=>{

    })
  },[])

  const getUpdateUser = (updatedUser) =>{
    API.get('/team-member ', { 
      headers: { Authorization: 'Bearer ' + token } 
    }).then(response=>{

      setUsers((response.data).sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      ))
    }).catch(error=>{

    })
  }

  const handleInputChange = (e) => {
    const searchQuery = e.target.value?.toLowerCase();
    setSearchKey(e.target.value?.toLowerCase())
    
    if (searchQuery) {
      setUsers(
        users.filter(
          (user) =>
            user["member name"]?.toLowerCase().includes(searchQuery) ||
            user.username?.toLowerCase().includes(searchQuery)
        )
      );
    } else {
      API.get('/team-member', {
        headers: { Authorization: 'Bearer ' + token },
      })
        .then((response) => {
          setUsers(
            response.data.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            )
          );
        })
        .catch((error) => {
          console.error('Failed to fetch team members:', error);
        });
    }
  };

  const clearSearch = ()=>{
    setSearchKey('')
    API.get('/team-member', {
      headers: { Authorization: 'Bearer ' + token },
    }).then((response) => {
      setUsers(
        response.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )
      );
    }).catch((error) => {
      console.error('Failed to fetch team members:', error);
    });
  }

  return (
    <Box>
      <Card>
        <CardBody>
          <Box float={'left'}>
            <InputGroup>
                <Input
                  placeholder="Search with user name"
                  onChange={handleInputChange}
                  variant="filled"
                  focusBorderColor="teal.400"
                  value={searchKey}
                  w={'320px'}
                />
                <InputRightElement>
                  <IconButton
                    aria-label="Clear search"
                    icon={searchKey ? <CloseIcon /> : <SearchIcon />}
                    size="sm"
                    color="gray.500"
                    variant="ghost"
                    onClick={clearSearch}
                  />
                </InputRightElement>
            </InputGroup>
          </Box>
          <Box float={'right'}>
            <AddUser getUpdateUser={getUpdateUser} />
          </Box>
          <Box className='clearfix'></Box>
            <TableContainer>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Position</Th>
                    <Th>Permission</Th>
                    <Th>Level</Th>
                    <Th>Focus Area</Th>
                    <Th>Action Office</Th>
                    <Th>ELT</Th>
                    <Th>Comms User</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                  <Tbody>
                    {users.map((user) => (
                      <Tr key={user.id}>
                        <Td>
                          <Flex align="center">
                            <Avatar name={user["member name"]} size="sm" mr="2" />
                            <Box>
                              <Text fontWeight="bold" mb={'0'} textTransform={'capitalize'}>{user["member name"]}</Text>
                              <Text fontSize="sm" color="gray.500" mb={'0'}>
                                {user.username}
                              </Text>
                            </Box>
                          </Flex>
                        </Td>
                        <Td textTransform={'capitalize'}>
                          <Box maxW={'150px'} whiteSpace={'pre-wrap'}>
                            {user.position && user.position.replace(/_/g, ' ')}
                          </Box>
                        </Td>
                        <Td textTransform={'capitalize'}>{user.permission_type}</Td>
                        <Td>{user.level}</Td>
                        <Td>
                          <Box maxW={'150px'} whiteSpace={'pre-wrap'}>
                            {user.module_name}
                          </Box>
                        </Td>
                        <Td textAlign={'center'}>{user.action_office_user ? <Checkbox isDisabled defaultChecked /> : <Checkbox isDisabled />}</Td>
                        <Td textAlign={'center'}>{user.elt_user ? <Checkbox isDisabled defaultChecked /> : <Checkbox isDisabled />}</Td>
                        <Td textAlign={'center'}>{user.comms_user ? <Checkbox isDisabled defaultChecked /> : <Checkbox isDisabled />}</Td>
                        <Td>
                          {/* <EditUser id={user.id} getUpdateUser={getUpdateUser} user={user && user} /> */}
                          <DeleteUser id={user.id} getUpdateUser={getUpdateUser} />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
              </Table>
            </TableContainer>
        </CardBody>
      </Card>
    </Box>
  );
};

export default UsersAndPermission;
