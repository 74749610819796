import React, { useEffect, useState, useContext } from 'react';
import Styles from './Aspirations.module.css'
import { TableContainer, Box, Text, Spinner, Table, Thead, Tr, Th, Tbody, Td, Button } from '@chakra-ui/react';
import API from '../../../Services/API';
import EditMetric from './EditMetric/EditMetric';
import DeleteComponent from './DeleteComponent/DeleteComponent'
import ApproveComponent from './ApproveComponent/ApproveComponent';
import RejectComponent from './RejectComponent/RejectComponent';
import { Link } from 'react-router-dom';
import { EditContext } from '../../../Context/BreadcrumbsContext';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

const Metrics = () => {
    const token = window.localStorage.getItem("accessToken")
    const [metrics, setMetrics] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [hasPermit, setHasPermit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { user } = useContext(EditContext);

    const getMetricInfo = (page = 1) => {
        setLoading(true);
        API.get(`/mytask-metric?page=${page}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => {
            const sortedMetrics = response.data.results.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
            setMetrics(sortedMetrics);
            setTotalPages(response.data.total_pages);
            setCurrentPage(response.data.current_page);
            setLoading(false);
        })
        .catch(error => {
            setLoading(false);
        });
    };

    useEffect(() => {
        getMetricInfo(currentPage);
    }, [currentPage]);

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return (
        <>
            <TableContainer>
                <Table>
                    <Thead>
                        <Tr bg="gray.100">
                            <Th className={Styles.th} color="blue.600">Name</Th>
                            <Th className={Styles.th} color="blue.600">Organization</Th>
                            <Th className={Styles.th} color="blue.600">Focus Area</Th>
                            <Th className={Styles.th} color="blue.600">Type</Th>
                            <Th className={Styles.th} color="blue.600">strategy</Th>
                            <Th className={Styles.th} color="blue.600">Approval</Th>
                            <Th className={Styles.th} color="blue.600">Reject Reason</Th>
                            <Th className={Styles.th} color="blue.600">Approve</Th>
                            {/* {hasPermit && <Th className={Styles.th} color="blue.600">Approve</Th>} */}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            metrics && metrics.map((metric, index) =>
                                <Tr className={Styles.tr}>
                                    <Td className={Styles.td} whiteSpace="nowrap"> 
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                            <Link to={`/mytasks/metric/${metric.id}/${metric.name}`}>
                                                {metric.name}
                                            </Link>
                                        </Box>
                                    </Td>
                                    <Td className={Styles.td} whiteSpace="nowrap"> 
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                            <Link to={`pillar/${metric.module_id}/${metric.pillar_name}#${metric.metrictype}`}>
                                                {metric.pillar_name} 
                                            </Link>
                                        </Box>
                                    </Td>
                                    <Td className={Styles.td} whiteSpace="nowrap"> 
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                            <Link to={`action/${metric.module_id}/${metric.action_name}#metrics`}>
                                                {metric.action_name}
                                            </Link>
                                        </Box>
                                    </Td>
                                    <Td className={Styles.td} whiteSpace="nowrap"> {metric.metrictype} </Td>
                                    <Td className={Styles.td} whiteSpace="nowrap"> <Box maxW={'200px'} whiteSpace={'pre-wrap'}> {metric.strategy_name} </Box></Td>
                                    <Td className={Styles.td} whiteSpace="nowrap"> {metric.approval_status} </Td>
                                    <Td className={Styles.td} whiteSpace="nowrap"> 
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                            {metric.approval_status === "rejected" ? <>{metric.reject_reason}</> : null}
                                        </Box>
                                    </Td>
                                    <Td className={Styles.td+' actionButton'} textAlign={'center'}> 
                                        <ApproveComponent id={metric.id} getMetricInfo={getMetricInfo} />
                                        <RejectComponent id={metric.id} getMetricInfo={getMetricInfo} />
                                    </Td>
                                </Tr>
                            )
                        }
                    </Tbody>
                </Table>
                <Box display="flex" justifyContent="center" mt={4}>
                    <Button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        mx={1}
                        aria-label="Previous Page"
                    >
                        <ChevronLeftIcon />
                    </Button>
                    {[...Array(totalPages)].map((_, page) => (
                        <Button
                            key={page}
                            onClick={() => handlePageChange(page + 1)}
                            isActive={currentPage === page + 1}
                            mx={1}
                        >
                            {page + 1}
                        </Button>
                    ))}
                    <Button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        mx={1}
                        aria-label="Next Page"
                    >
                        <ChevronRightIcon />
                    </Button>
                </Box>
            </TableContainer>
        </>
    )
}

export default Metrics