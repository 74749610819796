import React, { useState, useEffect, useRef, useMemo } from "react";
import Styles from "../../MetricData/metricdata.module.css";
import { useParams, Link } from "react-router-dom";
import API from "../../../../Services/API";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
  Text,
} from "@chakra-ui/react";
import ApproveComponent from "./ApproveComponent/ApproveComponent";
import RejectComponent from "./RejectComponent/RejectComponent";
import LoaderSpinner from "../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner";
import View from "./View/View";

const ImpactStories = () => {
  const token = window.localStorage.getItem("accessToken");
  const [isLoading, setIsLoading] = useState(false);
  const [elrSectionOne, setElrSectionOne] = useState([]);
  const [preview, setPreview] = useState(null);
  const [viewOneId, setViewOneId] = useState(null);
  const [noData, setNoData] = useState(0);
  const [message, setMessage] = useState({ type: "", text: "" });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await API.get(`/impact-story`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data) {
          setElrSectionOne(response.data);
          setPreview(response.data[0]?.banner);
          setViewOneId(response.data.id);
          setNoData(0);
        }
      } catch (error) {
        setMessage({
          type: "error",
          text: error.response?.data?.error || "An error occurred",
        });
        setNoData(1);
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      fetchData();
    }
  }, [token]);

  const filteredData = useMemo(
    () =>
      elrSectionOne?.filter((item) =>
        ["Submitted", "Approved", "Approved by Comms Team"].includes(
          item.approval_status
        )
      ) || [],
    [elrSectionOne]
  );

  const getUpdatedPending = (metricTask) => {
    setElrSectionOne(metricTask);
    setPreview(metricTask?.[0]?.banner);
    setViewOneId(metricTask?.id);
    setIsLoading(false);
    setNoData(0);
    setMessage({ type: "success", text: "Status updated successfully" });
  };

  const getErrorUpdate = (error) => {
    setMessage({ type: "error", text: error });
  };

  const getNewUpdate = (response) => {
    setElrSectionOne(response.data);
    setPreview(response.data?.[0]?.banner);
    setViewOneId(response.data.id);
    setIsLoading(false);
    setNoData(0);
  };

  const getErrorUpdateNew = (error) => {
    setMessage({
      type: "error",
      text: error.response?.data?.error || "An error occurred",
    });
    setIsLoading(false);
    setNoData(1);
  };

  return (
    <>
      {isLoading && <LoaderSpinner />}

      {message.text && (
        <Box p="5px" color={message.type === "error" ? "red" : "#00a0da"}>
          {message.text}
        </Box>
      )}

      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr className={Styles.tr}>
              <Th className={Styles.th}>Period - Year</Th>
              <Th className={Styles.th}>Period - Qtr</Th>
              <Th className={Styles.th}>Impact Story</Th>
              <Th className={Styles.th}>Focus Area</Th>
              <Th className={Styles.th}>Created On</Th>
              <Th className={Styles.th}>Updated On</Th>
              <Th className={Styles.th}>Created By</Th>
              <Th className={Styles.th}>Approval Status</Th>
              <Th className={Styles.th}>Approve</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.length === 0 ? (
              <Tr>
                <Td colSpan="9" textAlign="center">
                  <Text color="gray.500">There is no data available.</Text>
                </Td>
              </Tr>
            ) : (
              filteredData.map((item) => (
                <Tr key={item.id} className={Styles.tr}>
                  <Td className={Styles.td}>{item.fiscal_year}</Td>
                  <Td className={Styles.td}>{item.fiscal_quarter}</Td>
                  <Td className={Styles.td}>
                    <Box maxW="150px" whiteSpace="normal">
                      {item.label}
                    </Box>
                  </Td>
                  <Td className={Styles.td}>
                    <Box maxW="150px" whiteSpace="normal">
                      {item.type === "pillar" ? (
                        <Link to={`/pillar/${item.module_id}/${item.pillar_name}`}>
                          {item.pillar_name}
                        </Link>
                      ) : (
                        <Link
                          to={`/action/${item.module_id}/${item.action_name}#impactStories#executiveleadershipreviews`}
                        >
                          {item.action_name}
                        </Link>
                      )}
                    </Box>
                  </Td>
                  <Td className={Styles.td}>
                    {new Date(item.created_at).toLocaleDateString()}
                  </Td>
                  <Td className={Styles.td}>
                    {new Date(item.updated_at).toLocaleDateString()}
                  </Td>
                  <Td className={Styles.td}>
                    <Box maxW="150px" whiteSpace="normal">{item.user}</Box>
                  </Td>
                  <Td className={Styles.td}>{item.approval_status}</Td>
                  <Td className="actionButton">
                    <Stack direction="row" spacing={0} align="center">
                      <ApproveComponent
                        id={item.id}
                        getUpdatedPending={getUpdatedPending}
                        defaultSelected={item.approval_status}
                        pillar={item.pillar}
                        getErrorUpdate={getErrorUpdate}
                        type={item.type}
                        action={item.action}
                      />
                      <RejectComponent
                        id={item.id}
                        getUpdatedPending={getUpdatedPending}
                        defaultSelected={item.approval_status}
                        pillar={item.pillar}
                        getErrorUpdate={getErrorUpdate}
                        type={item.type}
                        action={item.action}
                      />
                      <View
                        id={item.id}
                        pillar={item.action}
                        title={item.label}
                        action={item.action}
                        getNewUpdate={getNewUpdate}
                        getErrorUpdateNew={getErrorUpdateNew}
                      />
                    </Stack>
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ImpactStories;
