import React, { useState, useEffect, useRef, useMemo } from "react";
import Styles from "../../MetricData/metricdata.module.css";
import { useParams, Link } from "react-router-dom";
import API from "../../../../Services/API";
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Stack, 
} from "@chakra-ui/react";
import LoaderSpinner from "../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner";
import ApproveComponent from "./ApproveComponent/ApproveComponent";
import RejectComponent from "./RejectComponent/RejectComponent";
import View from "./View/View";

const ExecutiveLevelReview = () => {
  const token = window.localStorage.getItem("accessToken");
  
  const [isLoading, setIsLoading] = useState(false);
  const [elrSectionOne, setElrSectionOne] = useState([]);
  const [preview, setPreview] = useState(null);
  const [viewOneId, setViewOneId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [newErrorMessage, setNewErrorMessage] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    API.get("/executive-level-review", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        updateElrState(response.data);
        setSuccessMessage(null);
      })
      .catch((error) => {
        setNewErrorMessage(error.response?.data?.error || "An error occurred");
        setIsLoading(false);
      });
  }, [token]);

  const updateElrState = (data) => {
    setElrSectionOne(data || []);
    setPreview(data?.[0]?.banner || null);
    setViewOneId(data?.id || null);
    setIsLoading(false);
    setErrorMessage(null);
    setSuccessMessage("Status updated successfully");
  };

  const filteredData = useMemo(() => {
    return elrSectionOne.filter(
      (item) => ["Submitted", "submitted", "Approved"].includes(item.approval_status)
    );
  }, [elrSectionOne]);

  return (
    <>
      {isLoading && <LoaderSpinner />}
      {errorMessage && <Box p="5px" color="red">{errorMessage}</Box>}
      {successMessage && <Box p="5px" color="#00a0da">{successMessage}</Box>}
      {newErrorMessage ? (
        <Box fontSize="13px">{newErrorMessage}</Box>
      ) : (
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr className={Styles.tr}>
                <Th className={Styles.th}>Period - Year</Th>
                <Th className={Styles.th}>Period - Qtr</Th>
                <Th className={Styles.th}>Executive level review</Th>
                <Th className={Styles.th}>Focus Area</Th>
                <Th className={Styles.th}>Created On</Th>
                <Th className={Styles.th}>Updated On</Th>
                <Th className={Styles.th}>Created By</Th>
                <Th className={Styles.th}>Approval Status</Th>
                <Th className={Styles.th}>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredData.length === 0 ? (
                <Tr>
                  <Td colSpan="9" textAlign="center">There is no data available.</Td>
                </Tr>
              ) : (
                filteredData.map((item) => (
                  <Tr key={item.id} className={Styles.tr}>
                    <Td className={Styles.td}>{item.fiscal_year}</Td>
                    <Td className={Styles.td}>{item.fiscal_quarter}</Td>
                    <Td className={Styles.td}>
                      <Box maxW="150px" whiteSpace="normal">{item.label}</Box>
                    </Td>
                    <Td className={Styles.td}>
                      <Box maxW="150px" whiteSpace="normal">
                        {item.type === "pillar" ? (
                          <Link to={`/pillar/${item.module_id}/${item.pillar_name}`}>
                            {item.pillar_name}
                          </Link>
                        ) : (
                          <Link to={`action/${item.module_id}/${item.action_name}#executiveLevelReview#executiveleadershipreviews`}>
                            {item.action_name}
                          </Link>
                        )}
                      </Box>
                    </Td>
                    <Td className={Styles.td}>{new Date(item.created_at).toLocaleDateString()}</Td>
                    <Td className={Styles.td}>{new Date(item.updated_at).toLocaleDateString()}</Td>
                    <Td className={Styles.td}>
                      <Box maxW="150px" whiteSpace="normal">{item.user}</Box>
                    </Td>
                    <Td className={Styles.td}>{item.approval_status}</Td>
                    <Td className="actionButton">
                      <Stack direction="row" spacing={2} align="center">
                        <ApproveComponent 
                          id={item.id} 
                          getUpdatedPending={updateElrState} 
                          defaultSelected={item.approval_status} 
                          pillar={item.pillar} 
                          getErrorUpdate={setErrorMessage}
                          type={item.type}
                          action={item.action}
                        />
                        <RejectComponent 
                          id={item.id} 
                          getUpdatedPending={updateElrState} 
                          defaultSelected={item.approval_status} 
                          pillar={item.pillar} 
                          getErrorUpdate={setErrorMessage}
                          type={item.type}
                          action={item.action}
                        />
                        <View
                          id={item.id}
                          pillar={item.action}
                          title={item.label}
                          action={item.action}
                          getNewUpdate={updateElrState}
                          getErrorUpdateNew={setNewErrorMessage}
                        />
                      </Stack>
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ExecutiveLevelReview;
