import React, { useEffect, useState } from 'react'
import Styles from './slideThree.module.css';
import API from '../../../../../../../Services/API';
import { Link, useParams } from 'react-router-dom';
import { Box, Heading, Img, Text, Button } from '@chakra-ui/react';
import FooterImg from '../../../../../../../../assets/images/cisco-white.png';
import {Table,Thead,Tbody,Tr,Th,Td,TableContainer,} from "@chakra-ui/react";
import DataTable from './DataTable/DataTable';
import Status from './Status/Status';
import LoaderSpinner from '../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../../Widgets/ErrorWidget/ErrorWidget';

const SlideThree = (props) => {
  const { actionid } = useParams();
  const token = window.localStorage.getItem("accessToken");
  const [action, setAction] = useState(null);
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(()=>{
    API.get(`/pillar?module_id=${actionid}`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then((response) => {
      setAction(response.data);
    }).catch((error) => {

    })
  }, [token, actionid])

  const getUpdate = (status)=> {
    setStatus(status)
    props.getSlide3Status(status)
  }

  const submitForApproval = () => {
    setIsLoading(true)
    setMessage(null)
    API.get(`/pillar?module_id=${actionid}`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then((response) => {
      setAction(response.data);

      API.get(`/qbr-screen-status?screen_no=2&action_id=${response.data.id}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        if(response.data[0].approval_status === "Ready to Submit"){
          const body = {
            "id":response.data[0].id,
            "screen": 2,
            "selectedfy": response.data[0].selectedfy,
            "action": Number(response.data[0].action),
            "module_id": Number(actionid),
            approval_status: "Submitted",
          }
          API.put(`/qbr-screen-status`, body, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          }).then(response=>{
            setStatus('Submitted')
            setMessage('Submitted for approval')
          }).catch(error=>{
          })
        }
      }).catch(error=>{
      })



      API.get(`/progress-next-steps?action_id=${response.data.id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
      })
      .then(response=>{
        const records = response.data;
        const readyToSubmitRecords = records.filter(record => record.approval_status === "Ready to Submit");
        if (readyToSubmitRecords.length > 0) {
          readyToSubmitRecords.forEach(record => {
            const body = {
              id: record.id,
              approval_status: "Submitted",
              "module_id":actionid,
            }
            API.put(`/progress-next-steps`, body, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }).then(putResponse => {
              setIsLoading(false)
              setStatus('Submitted')
              setMessage('Submitted for approval')
            }).catch(error => {
              setIsLoading(false)
              setMessage(
                error.response.data.non_field_errors ? error.response.data.non_field_errors[0] :
                error.response.data.msg ? error.response.data.msg : 'Facing technical error'
              )
            })
          });
        } else {
          setIsLoading(false)
        }
      }).catch(error=>{
        setIsLoading(false)
      }) 
    }).catch((error) => {
      setIsLoading(false)
    });
  }

  return (
    <>
      {isLoading && <LoaderSpinner />}
      {message && <ErrorWidget message={message} />}
      <Box id='slideThree'>
        <Box className={Styles.container} mt={'10px'}>
          <Box width={'100%'} maxWidth={'1080px'} m={'0 auto'}>
            <Heading mt={'20px'} fontWeight={'normal'}>
              Progress and Next Steps
              <Status screen={2} action={action} selectedFiscalYearID={props.selectedFiscalYearID} getUpdate={getUpdate} />
            </Heading>
            <Box minH={'400px'}>
              <DataTable
                selectedFiscalYearID={props.selectedFiscalYearID}
                getUpdate={getUpdate}
              />
            </Box>
            <Box float={'right'} mt={'-12px'} position={'relative'} zIndex={'9'}>
              {
                status === "Ready to Submit" ? <Button onClick={submitForApproval}> Submit for Approval </Button> : null
              }
            </Box>
            <Text fontSize="sm" position="relative" top="5px" left="0px" fontWeight="light" color="#ffffff">
              {/* <Img src={FooterImg} maxW={'40px'} display={'inline'} mr={'10px'} />  */}
              <Text fontSize={'11px'} as={'span'}>  2025 Purposefy and/or its affiliates. All rights reserved. Purposefy confidential.</Text>
            </Text>
          </Box>
        </Box>
      </Box>
    </>
    
  )
}

export default SlideThree