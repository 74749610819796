import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from '../../MetricData/metricdata.module.css';
import { useParams, Link } from 'react-router-dom';
import API from '../../../../Services/API';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, TableContainer, useDisclosure
} from '@chakra-ui/react';
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const ImpactStories = () => {
  const token = window.localStorage.getItem("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [elrSectionOne, setElrSectionOne] = useState([]);
  const [preview, setPreview] = useState(null);
  const [viewOneId, setViewOneId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    API.get(`/impact-story`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then((response) => {
        if (response.data && response.data.length > 0) {
          setElrSectionOne(response.data);
          setPreview(response.data[0]?.banner || null);
          setViewOneId(response.data[0]?.id || null);
        } else {
          setElrSectionOne([]);
          setPreview(null);
          setViewOneId(null);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error.response?.data?.error || 'An error occurred.');
        setIsLoading(false);
      });
  }, []);

  const getUpdatedPending = (metricTask) => {
    setElrSectionOne(metricTask);
    setPreview(metricTask?.[0]?.banner || null);
    setViewOneId(metricTask?.[0]?.id || null);
    setIsLoading(false);
  };

  const getErrorUpdate = (error) => {
    setErrorMessage(error || 'An error occurred.');
  };

  const filteredData = useMemo(() => {
    return elrSectionOne?.filter((item) =>
      ['Approve', 'Rejected', 'Approved by Comms Team', 'Approved by Action Office', 'Approved by Super Admin'].includes(item.approval_status)
    ) || [];
  }, [elrSectionOne]);

  const formatDate = (date) => (date ? new Date(date).toLocaleDateString() : 'N/A');

  return (
    <>
      {isLoading && <LoaderSpinner />}
      {errorMessage && <Box p={'5px'}>{errorMessage}</Box>}
      {!errorMessage && (
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr className={Styles.tr}>
                <Th className={Styles.th}>Period - Year</Th>
                <Th className={Styles.th}>Period - Qtr</Th>
                <Th className={Styles.th}>Impact Story</Th>
                <Th className={Styles.th}>Focus Area</Th>
                <Th className={Styles.th}>Created On</Th>
                <Th className={Styles.th}>Updated On</Th>
                <Th className={Styles.th}>Created By</Th>
                <Th className={Styles.th}>Approval Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredData.length === 0 ? (
                <Tr>
                  <Td colSpan="8">There is no data available.</Td>
                </Tr>
              ) : (
                filteredData.map((item, index) => (
                  <Tr key={item.id || index} className={Styles.tr}>
                    <Td className={Styles.td}>
                      <Box maxW={'150px'} whiteSpace={'normal'}>
                        {item.fiscal_year}
                      </Box>
                    </Td>
                    <Td className={Styles.td}>
                      <Box maxW={'150px'} whiteSpace={'normal'}>
                        {item.fiscal_quarter}
                      </Box>
                    </Td>
                    <Td className={Styles.td}>
                      <Box maxW={'150px'} whiteSpace={'normal'}>
                        {item.label}
                      </Box>
                    </Td>
                    <Td className={Styles.td}>
                      <Box maxW={'150px'} whiteSpace={'normal'}>
                        <Link to={`/action/${item.module_id}/${item.action_name}#impactStories#executiveleadershipreviews`}>
                          {item.action_name}
                        </Link>
                      </Box>
                    </Td>
                    <Td className={Styles.td}>{formatDate(item.created_at)}</Td>
                    <Td className={Styles.td}>{formatDate(item.updated_at)}</Td>
                    <Td className={Styles.td}>
                      <Box maxW={'150px'} whiteSpace={'normal'}>
                        {item.user}
                      </Box>
                    </Td>
                    <Td className={Styles.td}>
                      <Box maxW={'150px'} whiteSpace={'normal'}>
                        {item.approval_status}
                      </Box>
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ImpactStories;
