import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from '../../MetricData/metricdata.module.css';
import { useParams, Link } from 'react-router-dom';
import API from '../../../../Services/API';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Stack, Spinner
} from '@chakra-ui/react';
import JoditEditor from 'jodit-react';
import ApproveComponent from './ApproveComponent/ApproveComponent';
import RejectComponent from './RejectComponent/RejectComponent';
import View from './View/View';

const ExecutiveLeaderReporting = () => {
  const token = window.localStorage.getItem("accessToken");
  const editor = useRef(null);

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [elrSectionOne, setElrSectionOne] = useState([]);
  const [preview, setPreview] = useState(null);
  const [viewOneId, setViewOneId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  // Fetch Data on Mount
  useEffect(() => {
    if (!token) return;

    setIsLoading(true);
    API.get(`/elr-dashboard`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => {
        setElrSectionOne(response.data);
        setPreview(response.data?.[0]?.banner || null);
        setViewOneId(response.data?.id || null);
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
        setErrorMessage(error.response?.data?.error || 'Failed to fetch data.');
        setIsLoading(false);
      });

    return () => setIsLoading(false);
  }, [token]);

  // Filtered Data
  const filteredData = useMemo(() => 
    elrSectionOne?.filter(item => 
      ['Submitted', 'submitted', 'Approved'].includes(item.approval_status)
    ) || [], 
  [elrSectionOne]);

  // Success/Error Handlers
  const handleUpdate = (updatedData) => {
    setElrSectionOne(updatedData);
    setPreview(updatedData?.[0]?.banner || null);
    setViewOneId(updatedData?.id || null);
    setSuccessMessage('Status updated successfully');
  };

  const handleError = (error) => {
    setErrorMessage(error?.response?.data?.error || 'An error occurred.');
  };

  return (
    <>
      {isLoading && <Spinner size="lg" />}
      {errorMessage && <Box p="5px" color="red">{errorMessage}</Box>}
      {successMessage && <Box p="5px" color="#00a0da">{successMessage}</Box>}

      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr className={Styles.tr}>
              <Th className={Styles.th}>Period - Year</Th>
              <Th className={Styles.th}>Period - Qtr</Th>
              <Th className={Styles.th}>Executive Level Review</Th>
              <Th className={Styles.th}>Focus Area</Th>
              <Th className={Styles.th}>Created On</Th>
              <Th className={Styles.th}>Updated On</Th>
              <Th className={Styles.th}>Created By</Th>
              <Th className={Styles.th}>Approval Status</Th>
              <Th className={Styles.th}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.length ? (
              filteredData.map((item, index) => (
                <Tr key={index} className={Styles.tr}>
                  <Td className={Styles.td}>{item.fiscal_year_name || 'N/A'}</Td>
                  <Td className={Styles.td}>{item.fiscal_quarter_name || 'N/A'}</Td>
                  <Td className={Styles.td}>{item.label || 'N/A'}</Td>
                  <Td className={Styles.td}>
                    {item.type === 'pillar' ? (
                      <Link to={`/pillar/${item.module_id}/${item.pillar_name}`}>
                        {item.pillar_name || 'N/A'}
                      </Link>
                    ) : (
                      <Link to={`/action/${item.module_id}/${item.action_name}#executiveLeaderReporting#executiveleadershipreviews`}>
                        {item.action_name || 'N/A'}
                      </Link>
                    )}
                  </Td>
                  <Td className={Styles.td}>{item.created_at ? new Date(item.created_at).toLocaleDateString() : 'N/A'}</Td>
                  <Td className={Styles.td}>{item.updated_at ? new Date(item.updated_at).toLocaleDateString() : 'N/A'}</Td>
                  <Td className={Styles.td}>{item.user || 'N/A'}</Td>
                  <Td className={Styles.td}>{item.approval_status || 'N/A'}</Td>
                  <Td className="actionButton">
                    <Stack direction="row" spacing={2} align="center">
                      <ApproveComponent 
                        id={item.id} 
                        getUpdatedPending={handleUpdate} 
                        defaultSelected={item.approval_status} 
                        pillar={item.pillar} 
                        action={item.action}
                        getErrorUpdate={handleError}
                        type={item.type}
                      />
                      <RejectComponent 
                        id={item.id} 
                        getUpdatedPending={handleUpdate} 
                        defaultSelected={item.approval_status} 
                        action={item.action}
                        getErrorUpdate={handleError}
                        type={item.type}
                      />
                      <View
                        id={item.id}
                        pillar={item.action}
                        action={item.action}
                        title={item.label}
                        getNewUpdate={handleUpdate}
                        getErrorUpdateNew={handleError}
                        actionStatus={item.action_status}
                        metricResult={item.get_elr_metric_data || []}
                      />
                    </Stack>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="9" textAlign="center">There is no data available.</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ExecutiveLeaderReporting;
