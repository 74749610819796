import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from './metricdashboard.module.css'
import $ from 'jquery'
import API from '../../../Services/API';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Heading, Card, CardBody, Box, Text, Stack, StackDivider, Button, Tabs, TabList, TabPanels, Tab, TabPanel, SimpleGrid} from '@chakra-ui/react'
import CreateMetric from './CreateMetric/CreateMetric'
import OPSMetrics from './OPSMetrics/OPSMetrics'
import Financials from './Financials/Financials';
import ImpactMetrics from './ImpactMetrics/ImpactMetrics'
import BoardMetrics from './BoardMetrics/BoardMetrics'
import AllMetrics from './AllMetrics/AllMetrics'
import Initiatives from './Initiatives/Initiatives';
import ExecutiveLeadershipReviews from './ExecutiveLeadershipReviews/ExecutiveLeadershipReviews';
import SentimentInsights from './SentimentInsights/SentimentInsights';
import LoaderSpinner from '../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const MetricDashboard = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [isLoading, setIsLoading]  = useState(false)
    const [activeTab, setActiveTab] = useState('operational');
    const [fiscalYearList, setFiscalYearList] = useState([])
    const [fiscalQtrList, setFiscalQtrList] = useState([])
    const [selectedOption, setSelectedOption] = useState(null);
    const [metricType, setMetricType] = useState(null);
    const [filteredMetric, setFilteredMetric] = useState(null);
    const [value, setValue] = useState();
    const [filterStatus, setFilterStatus] = useState(0);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    
    useEffect(()=>{
        setIsLoading(true)
        const currentHash = window.location.hash ? window.location.hash.slice(1) : 'operational';
        setActiveTab(currentHash)
        
        API.get(`/fiscal-year-detail`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setFiscalYearList(response.data[0].fiscalyear)
            setFiscalQtrList(response.data[1].fiscalquarter)
            setIsLoading(false)
        }).catch(error=>{
            setIsLoading(false)
        })
    },[props.pillarId])
    
    const handleChange = (event) => {
        if (!event) {
            setSelectedOption(null);
            setFilteredMetric([]);
            setIsLoading(false);
            setFilterStatus(0);
            setSelectedType(null)
            setSelectedValue(null)
            return;
        }else{
            setSelectedType(event.name)
            setSelectedValue(event.value.split("_")[1])
            setFilterStatus(1)
        }
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'operational': return <OPSMetrics getUpdatedMetrics={getUpdatedMetrics} selectedValue={selectedValue} selectedType={selectedType} editable={props.editable} />;
            case 'financials': return <Financials getUpdatedMetrics={getUpdatedMetrics} selectedValue={selectedValue} selectedType={selectedType} editable={props.editable} />;
            case 'impactmetrics': return <ImpactMetrics getUpdatedMetrics={getUpdatedMetrics} selectedValue={selectedValue} selectedType={selectedType} editable={props.editable} />;
            case 'allmetrics': return <AllMetrics getUpdatedMetrics={getUpdatedMetrics} selectedValue={selectedValue} selectedType={selectedType} editable={props.editable} />;
            case 'initiatives': return <Initiatives getUpdatedMetrics={getUpdatedMetrics} pillarId={props.pillarId} editable={props.editable} actions={props.actions} />;
            case 'executiveleadershipreviews': return <ExecutiveLeadershipReviews getUpdatedMetrics={getUpdatedMetrics} pillarId={props.pillarId} editable={props.editable} actions={props.actions} />;
            case 'sentimentinsights': return <SentimentInsights pillarId={props.pillarId} />;
            default : return null;
        }
    };
    
    const getUpdatedMetrics = () =>{
        renderTabContent()
    }

    const flattenedOptions = useMemo(() => {
        return fiscalYearList.slice().reverse().reduce((acc, year) => {
          acc.push({ value: `year_${year.id}`, label: year.name, name: 'year' });
          const quarterOptions = fiscalQtrList
            .filter(quarter => quarter.name.includes(year.name.slice(-2))) // Matching fiscal year and quarters
            .map(quarter => ({
              value: `qtr_${quarter.id}`,
              label: quarter.name,
              name: 'qtr'
            }));
          return acc.concat(quarterOptions);
        }, []);
    }, [fiscalYearList, fiscalQtrList]);
  
    const sortedOptions = [...flattenedOptions].sort((a, b) => {
        const yearA = a.label.match(/FY(\d+)/);
        const yearB = b.label.match(/FY(\d+)/);
        const yearNumA = yearA ? parseInt(yearA[1], 10) : 0;
        const yearNumB = yearB ? parseInt(yearB[1], 10) : 0;
        if (yearNumA === yearNumB) {
            if (a.name === 'year' && b.name === 'qtr') return -1;
            if (a.name === 'qtr' && b.name === 'year') return 1;
            const quarterA = a.label.match(/Q(\d+)/);
            const quarterB = b.label.match(/Q(\d+)/);
        
            if (quarterA && quarterB) {
                return parseInt(quarterB[1], 10) - parseInt(quarterA[1], 10);
            }
        }
        return yearNumB - yearNumA;
    });

    

    return (
        <>
            {isLoading ? <LoaderSpinner /> : null}
            <Heading className={Styles.customHeadingH3}>
                Metrics Dashboard
                {props.editable ? <CreateMetric getUpdatedMetrics={getUpdatedMetrics} pillarMetricId={props.pillarMetricId && props.pillarMetricId} pillarId={props.pillarId && props.pillarId} /> : null } 
                <Box className={Styles.clearFix}></Box>
            </Heading>
            <Box mb={'15px'} width={'100%'} maxW={'520px'}>
                <Select
                    onChange={handleChange}
                    options={sortedOptions}
                    isClearable
                />
            </Box>
            <Box>
                <Card>
                    <CardBody>
                        <Box className={Styles.tabGroup}>
                            <Button onClick={() => setActiveTab('operational')} className={activeTab === 'operational' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>OPS Metrics</Button>
                            <Button onClick={() => setActiveTab('financials')} className={activeTab === 'financials' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Financials</Button>
                            <Button onClick={() => setActiveTab('impactmetrics')} className={activeTab === 'impactmetrics' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Impact Metrics</Button>
                            <Button onClick={() => setActiveTab('allmetrics')} className={activeTab === 'allmetrics' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>All Metrics</Button>
                            {/* <Button onClick={() => setActiveTab('sentimentinsights')} className={activeTab === 'sentimentinsights' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Sentiment Insights</Button> */}
                            {/* <Button onClick={() => setActiveTab('initiatives')} className={activeTab === 'initiatives' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Initiatives</Button> */}
                            {/* <Button onClick={() => setActiveTab('executiveleadershipreviews')} className={activeTab === 'executiveleadershipreviews' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Executive Leadership Reviews</Button> */}
                        </Box>
                        
                        <Box className={Styles.tabContent}>
                            {renderTabContent()}
                        </Box>
                    </CardBody>
                </Card>
            </Box>
        </>
    )
}

export default MetricDashboard

