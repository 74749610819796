import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import Styles from './mytasks.module.css'
import { Box, Button, Card, CardBody, Link } from '@chakra-ui/react'
import BreadCrumbs from '../../Components/Widgets/BreadCrumbs/BreadCrumbs';


import { EditContext } from "../../Context/BreadcrumbsContext";
import Faqs from '../Help/FAQs/Faqs';
import AboutSio from '../Help/AboutSio/AboutSio';
import Training from '../Help/Training/Training';
import API from '../../Services/API';
import { useNavigate } from 'react-router-dom';
const MyTasks = () => {
    const navigate = useNavigate()
    const token = window.localStorage.getItem("accessToken")
    const [editable, setEditable] = useState();
    const geteditStatus = (isEdit) =>{
      setEditable(isEdit)
    }
    const { edit, setEdit, currentHelpTab,setHelpTab } = useContext(EditContext);
    const [activeTab, setActiveTab] = useState(currentHelpTab);
    const renderTabContent = () => {
        switch (activeTab) {
            case 'FAQs': return <Faqs />;
            case 'About': return <AboutSio />;
            case 'Training Guide Training Videos': return <Training />;
            default : return null;
        }
    };

    useEffect(()=>{
      setHelpTab(activeTab)
    },[activeTab])


    return (
      <>
        <BreadCrumbs geteditStatus={geteditStatus} title={"Help"} />
        <Box>
          <Card>
            <CardBody>
              <Box>
                <Button
                  onClick={() => {
                    setActiveTab("FAQs")

                  }}
                  className={
                    activeTab === "FAQs"
                      ? `${Styles.active} ${Styles.tabStyle}`
                      : `${Styles.tabStyle}`
                  }
                >
                  FAQs
                </Button>
                <Button
                  onClick={() => setActiveTab("About")}
                  className={
                    activeTab === "About"
                      ? `${Styles.active} ${Styles.tabStyle}`
                      : `${Styles.tabStyle}`
                  }
                >
                  About
                </Button>
                <Button
                  onClick={() => setActiveTab("Training Guide Training Videos")}
                  className={
                    activeTab === "Training Guide Training Videos"
                      ? `${Styles.active} ${Styles.tabStyle}`
                      : `${Styles.tabStyle}`
                  }
                >
                  Training Guides & Videos
                </Button>
              </Box>
              <Box className={Styles.tabContent}>{renderTabContent()}</Box>
            </CardBody>
          </Card>
        </Box>
      </>
    );
}

export default MyTasks