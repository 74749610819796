import React, { useState, useEffect, useRef, useMemo } from "react";
import Styles from "../../MetricData/metricdata.module.css";
import { useParams, Link } from "react-router-dom";
import API from "../../../../Services/API";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import LoaderSpinner from "../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner";

const ExecutiveLevelReview = () => {
  const token = window.localStorage.getItem("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [elrSectionOne, setElrSectionOne] = useState([]);
  const [preview, setPreview] = useState(null);
  const [viewOneId, setViewOneId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [newErrorMessage, setNewErrorMessage] = useState(null);
  const [noData, setNoData] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await API.get(`/executive-level-review`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = response.data || [];
        setElrSectionOne(data);
        setPreview(data[0]?.banner || null);
        setViewOneId(data[0]?.id || null);
        setNoData(data.length ? 0 : 1);
      } catch (error) {
        setNewErrorMessage(error.response?.data?.error || "An error occurred.");
        setNoData(1);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const filteredData = useMemo(() => {
    return (
      elrSectionOne?.filter((item) =>
        [
          "Approve",
          "Rejected",
          "Approved by Comms Team",
          "Approved by Action Office",
          "Approved by Super Admin",
        ].includes(item.approval_status)
      ) || []
    );
  }, [elrSectionOne]);

  const tableRows = useMemo(() => {
    if (!filteredData.length) {
      return (
        <Tr>
          <Td colSpan="8">There is no data available.</Td>
        </Tr>
      );
    }
    return filteredData.map((item, index) => (
      <Tr key={index} className={Styles.tr}>
        <Td className={Styles.td}>
          <Box maxW={"150px"}>{item.fiscal_year}</Box>
        </Td>
        <Td className={Styles.td}>
          <Box maxW={"150px"}>{item.fiscal_quarter}</Box>
        </Td>
        <Td className={Styles.td}>
          <Box maxW={"150px"}>{item.label}</Box>
        </Td>
        <Td className={Styles.td}>
          <Box maxW={"150px"}>
            <Link to={`/action/${item.module_id}/${item.action_name}#executiveLevelReview#executiveleadershipreviews`}>
              {item.action_name}
            </Link>
          </Box>
        </Td>
        <Td className={Styles.td}>{new Date(item.created_at).toLocaleDateString()}</Td>
        <Td className={Styles.td}>{new Date(item.updated_at).toLocaleDateString()}</Td>
        <Td className={Styles.td}>
          <Box maxW={"150px"}>{item.user}</Box>
        </Td>
        <Td className={Styles.td}>
          <Box maxW={"150px"}>{item.approval_status}</Box>
        </Td>
      </Tr>
    ));
  }, [filteredData]);

  return (
    <>
      {isLoading && <LoaderSpinner />}
      {errorMessage && <Box p={"5px"}>{errorMessage}</Box>}
      {newErrorMessage ? (
        <Box fontSize={"13px"}>{newErrorMessage}</Box>
      ) : (
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr className={Styles.tr}>
                <Th className={Styles.th}>Period - Year</Th>
                <Th className={Styles.th}>Period - Qtr</Th>
                <Th className={Styles.th}>Executive level review</Th>
                <Th className={Styles.th}>Focus Area</Th>
                <Th className={Styles.th}>Created On</Th>
                <Th className={Styles.th}>Updated On</Th>
                <Th className={Styles.th}>Created By</Th>
                <Th className={Styles.th}>Approval Status</Th>
              </Tr>
            </Thead>
            <Tbody>{tableRows}</Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ExecutiveLevelReview;
