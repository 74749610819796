import React, { useContext } from 'react';
import { EditContext } from '../../../../../../../Context/BreadcrumbsContext';
import Styles from './slideOne.module.css';
import { Box, Img, Text } from '@chakra-ui/react';
import FooterImg from '../../../../../../../../assets/images/cisco-white.png';
import Title from './Title';
import FiscalYear from './FiscalYear/FiscalYear';

const SlideOne = () => {
  const {edit} = useContext(EditContext);

  return (
    <Box id='slideOne'>
      <Box className={Styles.container}>
        <Text className={Styles.slideLogo}>
          {/* <Img src={FooterImg} maxW={'60px'} /> */}
        </Text>
        <Title />
        <Box fontSize="sm" position="absolute" bottom="20px" left="20px" fontWeight="light" color="#ffffff">
          <FiscalYear />
        </Box>
      </Box>
    </Box>
  );
};

export default SlideOne;
